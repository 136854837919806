<template>
    <transition name="slide-up">
        <span class="reload-button" @click="newArticles = 0; $emit('reload');" v-show="show">
            <inline-svg :src="require('@/assets/images/ic_arrow_down.svg')" height="16" class="icon" />
            <span>{{ $tc('new_messages', this.newArticles, {c: this.newArticles}) }}</span>
        </span>
    </transition>
</template>
<script>
import EventsBus from '../../utils/EventsBus';

export default {
	name: 'reloadButton',
	props: {
		type: {
			type: String,
			default: 'news'
		},
		fetchNews: {},
	},
	data() {
		return {
			newArticles: 0
		};
	},
	computed: {
		show: function () {
			return this.newArticles > 0;
		}
	},
	mounted() {
		EventsBus.on('newPost:' + this.type, ( val ) => {
			if( val > 0)
				this.newArticles = val;
			else
				this.newArticles = 0;
		});
	}
};
</script>
