<template>
    <article class="article"
             :class="{ 'article--single' : fullView, 'is-closing' : isClosing,  'no-scroll' : galleryOpen }"
             :style="style"
             :id="'news_' + uid">

        <figure class="article-banner article-banner--video" v-if="article.youtube.length">
            <span v-if="!videoClicked" class="video-preview" @click.stop="activateVideo"><img :src="videoPoster[1]" /><inline-svg :src="require('@/assets/images/ic_youtube.svg')" height="64" /></span>
            <youtube :player-vars="{showinfo : 0, modestbranding:1, rel:0}" :src="article.youtube[0]" ref="youtube" v-else/>
        </figure>

        <figure class="article-banner" v-else-if="article.images.length">
            <picture>
                <source :srcset="article.images[0].urlLarge" media="(min-width: 1024px)" />
                <img :src="article.images[0].url" :alt="article.images[0].title">
            </picture>
        </figure>

        <header class="article-header" v-if="layout !== 'impression' || fullView">

            <h1 class="article-title">{{ article.title }}</h1>

            <div class="meta">
                <template v-if="isNew"><span class="category category--new">Neu</span></template>
                <template v-if="article.author.uid">
                    <router-link :to="{path: '/profil/' + article.author.uid }" class="author">{{ article.author.name }}</router-link>
                </template>
                <template v-if="articleCategories"><span class="category" v-for="category in articleCategories" v-bind:key="category.uid">{{ category.title }}</span></template>
                <time :datetime="article.datetime">{{$filters.timeSince(article.datetime)}}</time>
            </div>

            <p class="article-controls" v-if="fullView && ! isClosing">
                <span @click.stop="addLike( )" :class="{'is-liked': this.isLiked}" v-if="fullView" class="article-like"><i class="icon-like"></i></span>
            </p>
        </header>
        <div class="article-intro" v-if="generatedTeaser !== '' && teaserLength !== 0">
            <p v-html="generatedTeaser"></p>
        </div>

        <template v-if="layout === 'news'">
            <template v-if="fullView">
                <div class="article-content">
                    <div v-html="article.bodytext"></div>
<!--                    <download-list :files="article.downloads" v-if="article.downloads.length" />-->
                    <p v-if="hasGallery"><span @click="openGallery" class="button">{{ $t('gallery') }} <i class="icon-picture"></i></span></p>
                </div>
                <transition name="slide-left">
                    <gallery :images="gallery" v-if="galleryOpen" />
                </transition>

            </template>

        </template>

        <footer class="article-footer">
            <template v-if="!fullView">
                <div class="article-social">
                    <span>{{ $tc( 'comment',article.comments.length, {c:article.comments.length}) }} </span>
                    <span @click.stop="addLike( )" :class="{'is-liked': this.isLiked}" class="article-like"><i class="icon-like"></i><span v-text="likeCount" v-if="article.likes.length > 0" class="article-like_count"></span></span>
                </div>
            </template>

            <KappNewsComment v-else :comments="article.comments" :newsid="article.uid" :target="layout" />

        </footer>
    </article>
</template>

<script>

import api, { ApiDefaults } from '@/utils/api_call';
import KappNewsComment from './KappNewsComment';
import KappGallery from '../Subparts/KappGallery';
import { mapGetters } from 'vuex';
// const FULLVIEW_TIMING = .5;
import Youtube from "vue3-youtube"
// import { DELETE_ARTICLE } from '../../utils/store/actions/general';


export default {
    name: "KappNews",
    components: {
        'gallery': KappGallery,
        KappNewsComment,
        'youtube' : Youtube
    },
    props: {
        article: {
			type: Object,
            required: true
        },
        uid: {
            type: Number,
            required: true
        },
        single: {
			type: Boolean,
            default: false
        },
        available_categories: {
            type: Array,
            default: function(){
				return []
			}
        },
        layout: {
            type: String,
            default: 'news'
        },
        like_settings: {
            type: Object,
            default: function () {
                return {
                    pid: 17,
                    target: 'news'
                };
            }
        },
		teaserLength: {
			type: Number,
            default: 70
        }
    },
    data() {
        return {
            fullView: false,
            style: {},
            startPosition: {},
            endPosition: {
                width: window.innerWidth,
                height: window.innerHeight,
                top: 0,
                left: 0
            },
            animation: null,
            isClosing: false,
            galleryOpen: false,
            videoClicked: false
        };
    },
    methods: {
		activateVideo : function(){
			this.videoClicked = true;
        },
		/*isEditable: function ( article ){
			console.log(article.author && article.author.uid === this.getProfile.uid);
			return article.author && article.author.uid === this.getProfile.uid;
        },
        editArticle:function(article){
			console.log(article)
        },
		async deleteArticle (article) {
			const ok = await this.$refs.confirmDialog.show({
				title: 'Beitrag löschen',
				message: 'Wollen Sie den Beitrag wirklich löschen?',
				okButton: 'Ja, löschen!',
			})

			if( ok ) {
				let target;
				switch ( article.doktype ) {
					case 0 :
						target = 'news';
						break;
					case 3 :
						target = 'event';
						break;
					case 4 :
						target = 'bulletin';
						break;
				}
				this.$store.dispatch(DELETE_ARTICLE, {uid: article.uid, target: target })
			}
		},*/
        closed: function () {

            this.$el.style.position = 'static';

            if ( this.$el.previousElementSibling ) {
                this.$el.previousElementSibling.style.marginBottom = 0;
            }
            else if ( this.$el.nextElementSibling ) {
                this.$el.nextElementSibling.style.marginTop = '15px';
            }
            this.isClosing = false;
            this.fullView = false;
        },
        addLike() {
            let postData = new URLSearchParams();
            postData.append('tx_c3json_jsondataplugin[controller]', 'JsonDataNews');
            postData.append('tx_c3json_jsondataplugin[action]', 'toggleLike');
            postData.append('tx_c3json_jsondataplugin[news]', this.article.uid);
            postData.append('tx_c3json_jsondataplugin[user]', this.getProfile.uid);

            let apiParams = Object.assign({}, ApiDefaults, {
                params: {id: this.like_settings.pid},
                method: 'post',
                data: postData
            });
            api(apiParams).then(
                              ( response ) => {
                                  if ( typeof response.data.newsDetail != 'undefined' ) {
                                      // this.item.likes = response.data.newsDetail;
                                      // this.$store.commit(UPDATE_ARTICLE, {article: this.article, newData: {likes: response.data.newsDetail}, target: this.like_settings.target});
                                  }
                                  else {
                                      // this.item.likes = response.data;
                                      // this.$store.commit(UPDATE_ARTICLE, {article: this.article, newData: {likes: response.data}, target: this.like_settings.target});
                                  }

                              }
                          )
                          .catch(error => console.log('Error', error));

        },
        closeFullView() {
            this.isClosing = true;
        },

        showFullView() {
            if ( this.fullView ) return;

            let elStyle = window.getComputedStyle(this.$el),
                mTop = parseInt(elStyle.getPropertyValue('margin-top')),
                topPosition = (document.querySelector('.content-wrap').scrollTop * -1);

            topPosition += this.$el.offsetTop;

            this.startPosition = {
                position: 'fixed',
                top: topPosition + 'px', // #app paddingTop
                left: this.$el.offsetLeft + 'px',
                width: this.$el.offsetWidth + 'px',
                height: this.$el.offsetHeight + 'px'
            };
            let prev = this.$el.previousElementSibling;
            if ( prev ) {
                prev.style.marginBottom = ((mTop * 2) + this.$el.offsetHeight) + 'px';
            }
            else {
                let next = this.$el.nextElementSibling;
                if ( next )
                    next.style.marginTop = ((mTop * 2) + this.$el.offsetHeight) + 'px';
            }

            this.fullView = true;
        },
        openGallery() {
            this.galleryOpen = !this.galleryOpen;
        },
    },
    computed: {
		...mapGetters(['getProfile']),
		relatedNews() {
            return [];
        },
        likeCount() {
            let cnt = this.article.likes.length;
            if ( cnt > 99 ) {
                return '99+';
            }
            return cnt;
        },
        isLiked() {
            let likes = [].concat(this.article.likes);
            if ( typeof likes !== 'object' ) likes = [];
			return true;
        },
        isNew() {
			return false;
        },
        articleCategories: function () {

            let categories = this.available_categories,
                articleCategories = [];

            categories.forEach(value => {

				if ( Array.isArray(this.article.categories) ) {
					if ( this.article.categories
                     .find(function ( el ) {
                     return parseInt(el.uid) === parseInt(value.uid); }.bind(value.uid)) ) {
                        articleCategories[ articleCategories.length ] = value ;
					}
				}
				else {
					articleCategories[ 0 ] = this.article.categories;
				}
            });

            return articleCategories;
        },
        hasGallery() {
            return this.article.images.length > 1;
        },
        gallery() {
            let images = Array.from(this.article.images);
            if ( images.length <= 1 )
                return [];
            else if ( this.article.youtube.length === 0 )
                return images.splice(1);
            else
                return images;

        },
        generatedTeaser() {
			let text = '';

            if ( false === Object.prototype.hasOwnProperty.call(this.article,'teaser') || this.article.teaser.length === 0 ) {
                text = this.article.bodytext
                           .toString()
                           .replace(/(<([^>]+)>)/gi, "")
                           .substr(0, this.teaserLength );
            }
            else {
				text = this.article.teaser
                           .toString()
                           .substr( 0, this.teaserLength );
            }
			if( text.length === this.teaserLength)
				text +="&hellip;";

			return text;
        },
        videoPoster() {

			if( this.article.youtube[0] ){
				let thumbs = ['//i3.ytimg.com/vi/' + this.article.youtube[0] + '/maxresdefault.jpg','//i3.ytimg.com/vi/' + this.article.youtube[0] + '/hqdefault.jpg'];
				return thumbs;
            }
			return '';
        }
    },
    watch:{
		single: function ( val ){
			if(val) {
				this.fullView = true;
            }
        },
    },
    created() {
		if( this.single )this.fullView = true;
        // this.$pluralize.addPluralRule(/Kommentar$/i, 'Kommentare');
        // Events.$on('subpage.close', () => {
        //     this.galleryOpen = false;
        // });
    }
};
</script>

<style scoped>
.video-preview{
    cursor: pointer;
}
.video-preview::after{
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: rgba(255,255,255,.75);
}
.video-preview svg{
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    z-index: 5;
    fill: red;
}
</style>
