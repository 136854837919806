<template>
    <div class="comments">

        <template v-if="newsComments.length">
            <h3>{{ $tc( 'comment',newsComments.length, {c:newsComments.length}) }}</h3>
            <transition-group name="slide-left" tag="div" class="comments-list" v-if="commentList">
                <div class="comment" v-for="comment in commentList" :key="comment.uid">
                    <header>

                        <router-link :to="{path:'/profil/' + comment.userUid}">
                            <img v-if="comment.userImage" class="comment-avatar" :src="comment.userImage" :alt="comment.author" width="40" height="40" />
                            <span v-else class="comment-avatar comment-avatar--placeholder" @click.self="$emit('toggleContact',contact);toggleContact">{{ comment.initials }}</span>
                        </router-link>

                        <p>
                            <router-link :to="{path:'/profil/' + comment.userUid}" class="comment-author">{{ comment.author }}</router-link>
                            <time class="comment-date" :datetime="$filters.formatDate(comment.date,'YmdTHi')">{{ $filters.timeSince(comment.date) }}</time>

                        </p>
                        <div v-if="isEditable(comment)" class="comment-actions">
                            <span class="edit" @click="editComment(comment)">
                                <inline-svg :src="require('@/assets/images/ic_edit.svg')" height="12" />
                            </span>
                            <span class="delete" @click="deleteComment(comment)">
                                <inline-svg :src="require('@/assets/images/ic_delete.svg')" height="12" />
                            </span>
                        </div>
                    </header>
                    <main class="comment-text" v-html="comment.description" />
                    <figure v-if="comment.file" class="comment-figure">
                        <img :src="comment.file.src.thumb" width="40" :alt="comment.file.alt" @click.stop="this.lightboxImage = comment.file.src.full" />
                    </figure>
                </div>
            </transition-group>
            <Lightbox :is-visible="this.lightboxImage" @close="this.lightboxImage = false">
                <template v-slot:content>
                    <img :src="this.lightboxImage" width="800">
                </template>
            </Lightbox>
            <span class="load-more" v-if="commentCount > maxCount" @click="maxCount = maxCount + 3">Mehr laden</span>
        </template>
        <span class="comment-toggle button button--primary" @click="toggleForm" v-if="!writeComment">Neuer Kommentar</span>
        <div class="comments-form" v-show="writeComment">
            <h3>{{$t('comment_add')}}</h3>
            <template  v-if="isValidProfile">
            <div class="form">
                <p class="form-fieldwrap comment-text">
                    <label>{{$t('comment_new')}}</label>
                    <textarea rows="6" cols="25" :maxlength="(maxLength > 0 ? maxLength : false)" :placeholder="$t('comment_placeholder')" v-model="postData.description"></textarea>
                    <small v-if="maxLength > 0">{{$t('maxLength', {m:maxLength})}}</small>
                </p>
                <p class="form-fieldwrap comment-image">
                    <label>Bild</label>
                    <span class="form-file">Bild hochladen<input type="file" ref="file" class="form-file" @change="fileSelected" accept="image/*"></span>
                </p>
                <div v-if="previewImage" class="file-preview"><img :src="previewImage" /></div>
            </div>
            <p class="comment-submit">
                <button @click="submitComment" class="button button--primary">{{ $t('comment_submit') }}</button>
            </p>
            </template>
            <template v-else>
                <p>{{$t('profile_is_not_valid')}}</p>
                <p><router-link class="button button--secondary" :to="{path: '/mein_profil'}">zum Profil</router-link></p>
            </template>
        </div>
        <ConfirmDialog ref="confirmDialog"/>

    </div>
</template>

<script>
import _ from 'lodash';
import store from "@/utils/store";
import api, { ApiDefaults } from '../../utils/api_call';
import { UPDATE_ARTICLE } from '../../utils/store/actions/general';
import { mapGetters } from 'vuex';
import Lightbox from '../Plugins/Lightbox';
import { defineAsyncComponent } from 'vue';

export default {
	name: "KappNewsComment",
	components: {
		Lightbox,
		ConfirmDialog : defineAsyncComponent(()=>import('@/components/Plugins/ConfirmDialog')),

	},
	data() {
		return {
			maxCount: 3,
			maxLength: 0,
			writeComment: true,
			user: store.getters.getProfile,
			postData: {
				uid: 0,
				action: 'create',
				description: '',
				date: 0,
                file: false
			},
            previewImage: false,
			sendComments: [],
			sortOrder: 'desc',
			newsComments: this.comments,
			lightboxImage: false
		};
	},
	props: {
		'comments': {type: Array, required: false},
		'newsid': {type: Number, required: true},
		'target': {type: String, default: 'news'}
	},
	computed: {
		commentList: function () {
			return _.sortBy(this.newsComments, function ( a ) {
				return a.date;
			}).reverse().splice(0, this.maxCount);
		},
		commentCount() {
			if ( typeof this.comments !== 'undefined' ) {
				return this.comments.length;
			}
			else {
				return 0;
			}
		},
		...mapGetters([ 'getProfile','isValidProfile'])
	},
	methods: {
		isEditable : function(comment){
			return comment.userUid === this.user.uid;
		},
		deleteComment: async function ( comment ) {

			const ok = await this.$refs.confirmDialog.show({
				title: 'Kommentar löschen',
				message: 'Wollen Sie diesen Kommentar wirklich löschen?',
				okButton: 'Ja, löschen!'
			});

			if ( ok ) {

				let apiParams = {},
					postData = new FormData();

				postData.append('tx_nsnewscomments_newscomment[controller]', 'Comment');
				postData.append('tx_nsnewscomments_newscomment[action]', 'remove');
				postData.append('tx_nsnewscomments_newscomment[storagePid]', '9');
				postData.append('tx_nsnewscomments_newscomment[parentId]', '0');
				postData.append('tx_nsnewscomments_newscomment[comment][__identity]', comment.uid);

				apiParams = Object.assign({}, ApiDefaults,
					{
						headers: {
							'Content-Type': 'multipart/formdata'
						},
						params: {id: 8, type: 5},
						method: 'post',
						data: postData
					}
				);

				api(apiParams).then(( response ) => {
					if ( response.data.status === 'OK' ) {

						let idx = this.newsComments.findIndex(function ( item ) {
							return item.uid === comment.uid;
						}, comment);

						let newComments = Object.assign([], this.newsComments);
						newComments.splice(idx, 1);
						this.newsComments = newComments;

						this.$store.commit(
							UPDATE_ARTICLE,
							{
								article: {
									uid: this.newsid
								},
								newData: {
									comments: newComments
								},
								target: this.target
							}
						);
					}
				});
			}

		},
        editComment : function(comment){
			this.postData = Object.assign({},comment);
			this.postData.action = 'update';
			if( comment.file ) {
				this.previewImage = comment.file.src.full;
			}
        },
		toggleForm() {
			this.writeComment = !this.writeComment;
		},
		fileSelected: function ( evt ) {
			this.postData.file = evt.target.files[ 0 ];

			this.previewImage = URL.createObjectURL(this.postData.file);
		},
		appendComment( data ) {
			this.newsComments = [].concat.apply(this.newsComments, [ {
				author: this.getProfile.firstName + ' ' + this.getProfile.lastName,
				userImage: data.userImage,
				userUid: data.userUid,
				description: data.description,
				date: data.date,
				uid: data.uid,
				file: data.file
			} ]);

			this.$store.commit(
				UPDATE_ARTICLE,
				{
					article: {
						uid: this.newsid
					},
					newData: {
						comments: this.newsComments
					},
					target: this.target
				}
			);

		},
		submitComment() {
			if( this.postData.description.length === 0){
				this.$toast.error('Kommentar ist nicht ausgefüllt.');
				return;
            }

			let postData = new FormData(),
				apiParams = {};
			postData.append('tx_nsnewscomments_newscomment[controller]', 'Comment');
			postData.append('tx_nsnewscomments_newscomment[action]', this.postData.action);
			postData.append('tx_nsnewscomments_newscomment[storagePid]', '9');
			postData.append('tx_nsnewscomments_newscomment[parentId]', 0);

			postData.append('tx_nsnewscomments_newscomment[comment][description]', this.postData.description);
			postData.append('tx_nsnewscomments_newscomment[comment][newsuid]', this.newsid);
			postData.append('tx_nsnewscomments_newscomment[comment][feuserid]', this.getProfile.uid);
			postData.append('tx_nsnewscomments_newscomment[comment][usermail]', this.getProfile.email);
			postData.append('tx_nsnewscomments_newscomment[comment][username]', this.getProfile.firstName + ' ' + this.getProfile.lastName);
			postData.append('tx_nsnewscomments_newscomment[comment][userimage]', this.getProfile.image);
			postData.append('tx_nsnewscomments_newscomment[comment][pid]', 9);
			if( this.postData.uid ) {
				postData.append('tx_nsnewscomments_newscomment[comment][__identity]', this.postData.uid);
            }

			if( this.postData.file ) {
				postData.append('file', this.postData.file);
			}

			apiParams = Object.assign({}, ApiDefaults,
				{
					headers: {
						'Content-Type': 'multipart/formdata',
					},
					params: {id: 8, type: 5},
					method: 'post',
					data: postData
				}
			);

			api(apiParams)
				.then(( response ) => {
					let data = response.data,
                        action = this.postData.action;
					if( data.status === 'OK' ) {

						this.postData = {
							uid: 0,
							action: 'create',
							description: '',
							date: 0,
							file: false
						};
						this.previewImage = false;

						if ( Object.prototype.hasOwnProperty.call(data, 'comment') ) {
							if ( action === 'create' ) {
								this.appendComment(data.comment);
							}
							else if ( action === 'update' ) {
                                let idx = this.newsComments.findIndex(function(item){
									return item.uid === data.comment.uid;
                                });
								this.newsComments[idx] = data.comment;
							}
						}
                    }
				});
		}
	},
	watch: {
		comments: function ( val ) {
			this.newsComments = val;
		}
	},
	created() {
	}
};
</script>

<style scoped>
</style>
