<template>
    <div class="gallery" :data-columns="columnCount">
        <span v-for="(img,idx) in images" v-bind:key="idx" class="gallery-item"><img :src="img.urlOrig" alt=""></span>
    </div>
</template>

<script>
export default {
	name: "KappGallery",
	props: [ 'images', 'columns' ],
	computed: {
		columnCount: function () {
			return this.columns;
		}
	}
};
</script>

<style scoped>
.gallery[data-columns] {
    padding: 12px 15px;
}

.gallery[data-columns]::after {
    content: '';
    display: table;
    margin-left: auto;
    margin-right: auto;
    clear: both;
}

.gallery[data-columns='3'] {

}

.gallery[data-columns='3'] .gallery-item {
    float: left;
    width: 33.33%;
    width: calc(33.33% - 15px);
    margin-bottom: 15px;
    margin-left: 15px;
}

.gallery[data-columns='3'] .gallery-item:nth-child(3n+1) {
    margin-left: 0;
}
</style>