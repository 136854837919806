<template>
    <div id="start">
        <div class="content-block tar" style="" v-if="isAdmin">
        <span :title="$t('create_new_event')" class="button button--secondary button--big m-b-1"  @click="showForm"><inline-svg :src="require('@/assets/images/ic_new.svg')" width="24" class="icon" />Neuer Eintrag</span>
        </div>
        <div class="content-block" v-if="spotlight.length">
            <!-- Spotlight -->
            <div class="block-header"><h2>{{ $t('spotlight_title') }} <small>({{ spotlight.length }})</small></h2></div>
            <slider :pagination="false" max-width="calc(50% - 10px)">
                <news class="slide article--spotlight" v-for="sl in spotlight" :key="sl.uid" :article="sl" :uid="sl.uid" :available_categories="categories" :teaserLength=0 @click="openNews(sl)" />
            </slider>
        </div>

        <div class="content-block content-block--list">
            <!-- Newsfeed -->
            <div class="block-header"><h2>{{ $t('newsfeed_title') }}</h2></div>
            <template v-for="(nf,key) in newsfeed" :key="nf.uid">
                <news :article="nf" :uid="nf.uid" :available_categories="categories" @click="openNews(nf)" />

                <template v-if="showInBlock(key,'profiles')">
                    <div class="content-block">
                        <div class="block-header">
                            <h2>{{ $t('profiles_title') }} <small>({{profiles.length}})</small></h2>
                        </div>
                        <slider max-width="320px" :pagination="false">
                            <profile :profile="profile" v-for="(profile,idx) in profiles" :key="idx" class="profile slide" />
                        </slider>
                    </div>
                </template>

                <template v-if="showInBlock(key,'birthday')">
                    <div v-if="Object.entries(contactsBirthday).length" class="content-block">
                        <div class="block-header">
                            <h2>{{ $t('birthday_title') }}</h2></div>
                        <dl class="list list--birthdays">
                            <template v-for="(list,idx) in contactsBirthday" :key="idx">
                                <dt>{{$filters.formatDate(idx, 'j. F') }}</dt><dd><strong><template v-for="(cntct,idx) in list" :key="idx"><template v-if="idx>0">, </template>
                                <router-link :to="{path: '/profil/' + cntct.uid}">{{ cntct.firstName }} {{ cntct.lastName }}</router-link></template></strong></dd>
                            </template>
                        </dl>
                    </div>
                </template>

                <template v-if="key === 2">
                    <ProfileRandom />
                </template>

            </template>

            <span v-if="maxArticles < newsItems.length" class="button button--more" @click="maxArticles += 6">{{$t('load_more')}}</span>
        </div>
        <reloadButton @reload="fetchNews" />
        <Lightbox v-if="isAdmin" :is-visible="formOpen" @close="formOpen = false">
            <template v-slot:content>
                <NewsForm action="create" />
            </template>
        </Lightbox>
    </div>
</template>

<script>
import KappNews from '@/components/Blocks/KappNews';
import api, { ApiDefaults } from '../../utils/api_call';
import debounce from 'lodash.debounce';
import Profile from './Profile';
import Slider from '../../components/Subparts/Slider';
import { STORE_NEWS } from '../../utils/store/actions/general';
import { mapGetters } from 'vuex';
import ReloadButton from './ReloadButton';
import ProfileRandom from './ProfileRandom';
import { defineAsyncComponent } from 'vue';

export default {
	name: "KappMain",
	data() {
		return {
			// articles: [],
			categories: [],
			profiles: [],
            selectedArticle: false,
            newArticles: 0,
            formOpen: false,
            maxArticles: 6,
            backScroll: false
		};
	},
	components: {
		ProfileRandom,
		ReloadButton,
		Slider,
		Profile,
		'news': KappNews,
		// ConfirmDialog : defineAsyncComponent(()=>import('@/components/Plugins/ConfirmDialog')),
		NewsForm : defineAsyncComponent(()=>import('@/pages/Posts/KappPostsNew.vue')),
		Lightbox : defineAsyncComponent(()=>import('@/components/Plugins/Lightbox.vue')),
	},
	computed: {
		...mapGetters(['newsItems', 'isAdmin','contactsBirthday']),
		spotlight: function () {
			let spotlight = this.newsItems;
			return spotlight.filter(( item ) => item.top).sort(( a, b ) => {
				return b.datetime - a.datetime;
			});
		},
		newsfeed: function () {
			let newsfeed = this.newsItems;

			return newsfeed.slice(0,this.maxArticles);
		},
		showReloadButton: function(){
            return this.newArticles > 0;
        }
	},
	methods: {
		showInBlock: function(key,block){
			if ( block === 'profiles' ) {
				if ( key === 0 && this.profiles.length > 0 ) {
					return true;
				}
            }
			else if ( block === 'birthday' ) {
				if( (key === 1 && this.profiles.length > 0) || (key===0 && this.profiles.length === 0) ) {
					return true;
				}
            }
			else if ( block === 'random' ) {
				if( key === 0 && this.profiles.length === 0 && Object.entries(this.contactsBirthday).length === 0) {
					return true;
				}

				else if( key === 1 && (Object.entries(this.contactsBirthday).length === 0 && this.profiles.length > 0) || Object.entries(this.contactsBirthday).length > 0 && this.profiles.length === 0) {
					return true;
                }

				else if( key === 2 && this.profiles.length > 0 && Object.entries(this.contactsBirthday).length > 0) {
					return true;
                }
            }

			return false;
        },
		openNews: function(article) {
			this.$router.push({path:'/news/' + article.uid });
        },
        showForm: function(){
			this.formOpen = true;
        },
		fetchNews: debounce(function () {

			let apiParams = Object.assign({}, ApiDefaults, {method: 'get', params: {id: 2, 'tx_c3json_jsondataplugin[action]': 'news'}});
			api(apiParams)
				.then(( response ) => {
					if ( response.status === 200 ) {
						let articles = response.data.news.content.map(( item ) => {
													item.visible = true;
													return item;
												})
												.sort(( a, b ) => b.datetime - a.datetime );
						this.$store.commit(STORE_NEWS, articles);
						this.categories = Object.values(response.data.news.categories);
						this.newArticles = 0;
					}
				})
				.catch(( err ) => {
					console.warn(err.message);
				})
				.finally(() => this.hideArticleEffect = 'hideArticles');


		}, 125),
		fetchChangedProfiles: debounce(function () {
			let apiParams = Object.assign({}, ApiDefaults, {method: 'get', params: {id: 7, 'tx_c3json_jsondataplugin[action]': 'latest'}});

			api(apiParams)
				.then(( response ) => {
					if ( response.status === 200 ) {
						if( Object.prototype.hasOwnProperty.call(response.data, 'user')) {
							this.profiles = response.data.user;
						}
					}
				})
				.catch(( err ) => {
					console.warn(err.message);
				})
				.finally(() => this.hideArticleEffect = 'hideArticles');


		}, 125)
	},
	mounted() {
		this.fetchNews();
		this.fetchChangedProfiles();
	},
    updated(){
		if ( this.backScroll ) {
			let idx = this.newsItems.findIndex(( item ) => item.uid == this.backScroll),
				maxa = Math.max(idx + 1, this.maxArticles),
				newsId = "news_" + this.backScroll;
			this.maxArticles = (Math.ceil(maxa / 3) * 3);
			this.$nextTick(() => {
				let el = document.querySelector('.content-block.content-block--list #' + newsId);
				window.scrollTo({top: el.offsetTop});
			});

			this.backScroll = false;
		}
    },
	beforeRouteEnter( to, from, next ) {
		if ( from.name === 'SingleNews' ) {
			let uid = from.params.id;
			next( vm => {
                vm.backScroll = uid;
			})
		}
		else{
			next();
        }
	}

};
</script>

<style scoped>
.block-header{
    margin-bottom: 16px;
}






.content-block .content-block{
    margin: 32px 0;
}
</style>