<template>
    <div>
        <p>
            <router-link :to="{path:'/profil/' + profile.uid}"><strong>{{ profile.firstname }} {{ profile.lastname }}</strong></router-link><br />
            {{ $t('profile_info') }}
        </p>
        <div class="changes"><p v-for="(val,key) in profile.changes" :key="key">
            <span class="label">{{ $t('profile_' + key.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)) }}</span>
            <span class="value" v-if="key !== 'image'">{{ value(val, key) }}</span>
            <img :src="val" v-else :alt="profile.firstname + ' ' + profile.lastname" />
        </p></div>
    </div>
</template>
<script>
export default {
	name: 'profile',
	props: {
		profile: {}
	},
    computed: {
    },
	methods: {

		value: function ( val, key ) {
			switch ( key ) {
				case 'birthday': {
					let d = new Date();
					d.setTime(val * 1000);
					val = d.format('j. F');
					break;
				}
			}
			return val;
		}
	}
};
</script>
<style scoped>
.profile{
    border: 1px solid var(--color-grey-60);
    padding: 8px;
}
.profile a {
    color: var(--color-primary);
    text-decoration: none;
}
.changes {
    display: flex;
    flex-direction: column;
}

.changes p {
    border: 1px solid var(--color-grey-60);
    margin-top: 4px;
    padding: 16px 8px 12px;
}

.changes .label {
    font-size: var(--font-size-sm);
    display: block;
}

.changes .value {
    display: block;
}
.changes img {
    border-radius: 50%;
    display: block;
    margin: 0 auto;
}
</style>